import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Modal, TextField, Tooltip, Alert,
} from '@mui/material';
import axios from 'axios';

function SalaryManagement() {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null); // Track selected driver
  const [earnings, setEarnings] = useState([]);
  const [settlementAmount, setSettlementAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [settlementOpen, setSettlementOpen] = useState(false);
  const [settlementType, setSettlementType] = useState(''); // Track the settlement type: 'admin_to_driver' or 'driver_to_admin'
  const [disableSettleToDriver, setDisableSettleToDriver] = useState(false); // Disable "Settle to Driver"
  const [disableSettleToAdmin, setDisableSettleToAdmin] = useState(false); // Disable "Settle to Admin"
  const [errorMessage, setErrorMessage] = useState(''); // Error message for feedback
  const [successMessage, setSuccessMessage] = useState(''); // Success message for feedback

  useEffect(() => {
    fetchDriversWithEarnings();
  }, []);

  // Fetch drivers and earnings, including settled and pending amounts
  const fetchDriversWithEarnings = () => {
    setLoading(true);
    axios.get('https://kride.kirupam.shop/api/admin/earnings_management1.php?action=fetch_drivers_with_earnings')
      .then(response => {
        const drivers = response.data.drivers || [];
  
        const driversWithEarnings = drivers.map(driver => {
          const totalEarnings = parseFloat(driver.total_cost) || 0;
          const driverEarnings = (totalEarnings * 0.9).toFixed(2);
          const adminEarnings = (totalEarnings * 0.1).toFixed(2);
  
          // Use settled_driver and settled_admin from the response
          const totalSettledDriver = parseFloat(driver.settled_driver) || 0;
          const totalSettledAdmin = parseFloat(driver.settled_admin) || 0;
  
          const pendingDriver = (driverEarnings - totalSettledDriver).toFixed(2);
          const pendingAdmin = (adminEarnings - totalSettledAdmin).toFixed(2);
  
          return {
            ...driver,
            driverEarnings,
            adminEarnings: parseFloat(adminEarnings),
            totalSettledDriver: totalSettledDriver.toFixed(2),
            totalSettledAdmin: totalSettledAdmin.toFixed(2),
            pendingDriver: parseFloat(pendingDriver),
            pendingAdmin: parseFloat(pendingAdmin),
          };
        });
  
        driversWithEarnings.sort((a, b) => b.adminEarnings - a.adminEarnings);
  
        setDrivers(driversWithEarnings);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching drivers and earnings:', error);
        setLoading(false);
      });
  };
  

  const handleViewClick = (driver) => {
    setSelectedDriver(driver);
    fetchEarnings(driver.id);
    setOpen(true);
  };

  const fetchEarnings = (driverId) => {
    setLoading(true);
    axios.get(`https://kride.kirupam.shop/api/admin/earnings_management1.php?action=fetch_earnings&driver_id=${driverId}`)
      .then(response => {
        const fetchedEarnings = response.data.earnings || [];
        setEarnings(fetchedEarnings);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching earnings:', error);
        setLoading(false);
      });
  };

  const handleSettleClick = (driver, type) => {
    setSelectedDriver(driver); // Ensure the driver is set when settle button is clicked
    setSettlementType(type);
    setErrorMessage(''); // Clear error messages
    setSuccessMessage(''); // Clear success messages
    setSettlementOpen(true);
  };

  const handleSettlement = () => {
    if (!selectedDriver) {
      setErrorMessage('No driver selected for settlement.');
      return;
    }
    
    if (!settlementAmount || isNaN(settlementAmount)) {
      setErrorMessage('Please enter a valid settlement amount.');
      return;
    }

    axios.post('https://kride.kirupam.shop/api/admin/settle_earnings.php', {
      driver_id: selectedDriver.id, // Ensure selectedDriver has been set
      settled_amount: settlementAmount,
      settled_trips: earnings.map(e => e.trip_id).join(','),
      settled_by: 'admin',
      settlement_type: settlementType
    })
      .then(response => {
        setSuccessMessage('Settlement successful!');
        setSettlementOpen(false);
        fetchDriversWithEarnings();
      })
      .catch(error => {
        console.error('Error during settlement', error);
        setErrorMessage('Error during settlement. Please try again.');
      });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDriver(null);
    setEarnings([]);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Salary Management</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: '700px', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Driver ID</TableCell>
                <TableCell>Driver Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Total Driver Earnings</TableCell>
                <TableCell>Total Admin Earnings</TableCell>
                <TableCell>Total Settled Driver</TableCell>
                <TableCell>Total Settled Admin</TableCell>
                <TableCell>Pending Driver</TableCell>
                <TableCell>Pending Admin</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers.map(driver => (
                <TableRow key={driver.id}>
                  <TableCell>{driver.id}</TableCell>
                  <TableCell>{driver.driver_name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell style={{ color: driver.driverEarnings > 5000 ? 'red' : 'inherit' }}>
                    {driver.driverEarnings}
                  </TableCell>
                  <TableCell>{!isNaN(driver.adminEarnings) ? driver.adminEarnings.toFixed(2) : '0.00'}</TableCell>
                  <TableCell>{driver.totalSettledDriver}</TableCell>
                  <TableCell>{driver.totalSettledAdmin}</TableCell>
                  <TableCell style={{ color: driver.pendingDriver < 0 ? 'red' : 'green' }}>
                    {driver.pendingDriver}
                  </TableCell>
                  <TableCell style={{ color: driver.pendingAdmin < 0 ? 'red' : 'green' }}>
                    {driver.pendingAdmin}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleViewClick(driver)}>
                      View
                    </Button>
                    <Tooltip title={disableSettleToDriver ? "Settlement to driver disabled due to cash payments" : ""}>
                      <span>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleSettleClick(driver, 'admin_to_driver')} // Pass the driver to handleSettleClick
                          sx={{ ml: 1 }}
                          disabled={disableSettleToDriver}
                        >
                          Settle to Driver
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip title={disableSettleToAdmin ? "Settlement to admin disabled due to wallet payments" : ""}>
                      <span>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => handleSettleClick(driver, 'driver_to_admin')} // Pass the driver to handleSettleClick
                          sx={{ ml: 1 }}
                          disabled={disableSettleToAdmin}
                        >
                          Settle to Admin
                        </Button>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal
        open={settlementOpen}
        onClose={() => setSettlementOpen(false)}
        aria-labelledby="settle-earnings-title"
        aria-describedby="settle-earnings-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" id="settle-earnings-title" gutterBottom>
            {settlementType === 'admin_to_driver' ? `Settle to Driver` : `Settle to Admin`} for {selectedDriver?.driver_name}
          </Typography>
          <TextField
            label="Settlement Amount"
            value={settlementAmount}
            onChange={(e) => setSettlementAmount(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          <Button variant="contained" color="primary" onClick={handleSettlement}>
            Settle
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
};

export default SalaryManagement;
